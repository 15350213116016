<template>
  <div id="app" class="flex min-h-screen flex-col overflow-x-hidden bg-neutral-25 lg:ml-72">
    <div
      v-show="sidebarOpen && !largerThanLg"
      id="overlay"
      class="fixed bottom-0 left-0 right-0 top-0 z-40 h-full w-full bg-black/70"
    ></div>
    <base-layout-sidebar v-show="sidebarOpen || largerThanLg" />
    <slot></slot>
    <base-layout-footer />
    <controller-toast />
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useLayoutStore } from '~/stores/layout';

const layoutStore = useLayoutStore();

const { sidebarOpen } = storeToRefs(layoutStore);

const breakpoints = useBreakpoints(breakpointsTailwind);
const largerThanLg = breakpoints.greaterOrEqual('lg');

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (sidebarOpen.value && !largerThanLg.value) {
        return 'overflow-y-hidden';
      }
      return '';
    }),
  },
});
</script>
